import { UniteCloudHelper } from "./unitecloud-helper";
import { UrlMapping } from "./url-mapping";

export class UniteCloudPatientFileMapping implements UrlMapping {
    public remapUrl(hostname: string, path: string, args: string) : string | null {
        const match = path.match(/^\/uc\/v0\/p\/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})\/f\/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})$/);
        if (match) {
            const baseUrl = UniteCloudHelper.getBaseUrl(hostname);
            const patientId = UniteCloudHelper.formatId("Patient", match[1]);
            const fileId = UniteCloudHelper.formatId("Asset", match[2]);
            if (args && args.length > 0)
            {
                const matchArgs = args.match(/^\?c=([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})$/);        
                if (matchArgs) {
                    const companyId = matchArgs[1];
                    return `${baseUrl}/patients/${patientId}/files/${fileId}?companyId=${companyId}`;
                }
                else {
                    return null;
                }
            }
            return `${baseUrl}/patients/${patientId}/files/${fileId}`;
        }
        return null;
    }
}
