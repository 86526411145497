import { UrlMappingService } from './services/url-mapping-service';
import './styles/main.scss';

let footerElement = document.getElementById("footer");
if (window.location.hostname.endsWith(".com") && footerElement) {
    footerElement.className = "hide-it";
}

const mapper = new UrlMappingService();
const newUrl = mapper.remapUrl(window.location.hostname, window.location.pathname, window.location.search);
console.log(newUrl ?? "No redirection found!");

let redirectContainer = document.getElementById("redirectContainer");
if (redirectContainer) {
    if (newUrl) {
        redirectContainer.innerHTML =  `<a href="${newUrl}">Click to redirect to ${newUrl}</a>`;
    }
    else {
        redirectContainer.innerText = "No redirection found!";
    }
}

if (newUrl) {
    let redirectContent = document.getElementById("redirectContent");
    if (redirectContent) {
        redirectContent.className = "show-it";
    }
}
else {
    let notfoundContent = document.getElementById("notfoundContent");
    if (notfoundContent) {
        notfoundContent.className = "show-it";
    }
}

if (newUrl) {
    console.log("Redirecting to: ", newUrl);
    var meta = document.createElement('meta');
    meta.setAttribute('http-equiv', 'refresh');
    meta.setAttribute('content', '0; url=' + newUrl);
    document.getElementsByTagName('head')[0].appendChild(meta);
    window.location.href = newUrl;
}
