// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  padding: 0;
  margin: 0;
}

body {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  color: #313131;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.empty-state-icon-background {
  width: 96px;
  height: 96px;
  margin-bottom: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.12);
}

.empty-state-title {
  font-style: normal;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
}

.empty-state-message {
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.38);
  text-align: center;
}

.hide-it {
  display: none;
}

.show-it {
  font-family: Noto Sans, sans-serif;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a {
  color: #313131;
  text-decoration: none;
}

#redirectContainer {
  max-width: 90%;
  text-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

#footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 18px;
  text-align: center;
  padding: 3px;
  font-size: 14px;
  background-color: #dddddd;
}`, "",{"version":3,"sources":["webpack://./src/styles/main.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,SAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;EACA,UAAA;EACA,SAAA;EACA,yBAAA;EACA,cAAA;EACA,gDAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,qCAAA;AACJ;;AAEA;EACI,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,0BAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,kCAAA;EACA,YAAA;EACA,aAAA;EACA,UAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,cAAA;EACA,qBAAA;AACJ;;AAEA;EACI,cAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,yBAAA;AACJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
